
import {computed, defineComponent, ref, watch} from "vue";
import KTNotificationsMenu from "@/layout/header/partials/NotificationsMenu.vue";
import KTQuickLinksMenu from "@/layout/header/partials/QuickLinksMenu.vue";
import Messages from "@/layout/header/partials/Messages.vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import {getUiAvatarSrc} from "@/ayar";
import {l} from '@/core/helpers/lang'
import store from "@/store";
import CartAddRemoveButton from "@/components/backoffice/cart/cartAddRemoveButton.vue";
import {Cart} from "@/store/modules/AuthModule";
import NumberFormat from "@/core/functions/NumberFormat";
import _ from "lodash"

export default defineComponent({
  name: "Topbar",
  components: {
    KTNotificationsMenu,
    KTQuickLinksMenu,
    KTUserMenu,
    Messages,
    CartAddRemoveButton,
  },
  methods:{
    showPanel() {
      const body = document.querySelector<HTMLDivElement>("body");
      const elm = document.querySelector<HTMLDivElement>("#kt_quick_cart");
      const elm2 = document.querySelector<HTMLDivElement>(".offcanvas-overlay")
      if (elm && body) {
        elm.classList.add('offcanvas-on');
        body.classList.add('cartPanelShow');
      }
      if (elm2 ) {
        elm2.classList.remove('d-none');
      }
    },
    closePanel(){
      const body = document.querySelector<HTMLDivElement>("body");
      const elm = document.querySelector<HTMLDivElement>("#kt_quick_cart");
      const elm2 = document.querySelector<HTMLDivElement>(".offcanvas-overlay")
      if (elm && body) {
        elm.classList.remove('offcanvas-on');
        body.classList.remove('cartPanelShow');
      }
      if (elm2) {
        elm2.classList.add('d-none');
      }
    },
  },
  setup() {
    const carts = ref<Cart>({
      items: [],
      totalPriceWithoutTax: 0,
      totalTax: 0,
      totalDiscount: 0,
      totalHamPrice: 0,
      totalPrice: 0,
      totalQty: 0,
      userGeneralDiscount: 0
    });
    const computedCarts = computed(() => store.getters.currentUser.carts);

    watch(computedCarts, (newValue, oldValue) => {
      carts.value = newValue;
      items.value = newValue.items;
    })
    const items = ref([]);
    const totalQty = ref(0);

    const getSize = (items) => {
      return _.size(items)
    }

    watch(carts, (newValue, oldValue) => {
      items.value = newValue.items;
      totalQty.value = newValue.totalQty;
    })
    return {
      generalSettings: computed(() => store.getters.getGeneralSettingsList),
      user: computed(() => store.getters.currentUser),
      getUiAvatarSrc,
      l,
      carts,
      items,
      totalQty,
      NumberFormat,
      getSize
    }
  },
  mounted(){
    const elm = document.querySelector<HTMLDivElement>(".offcanvas-wrapper");
    const elm2 = document.querySelector<HTMLDivElement>(".centerEmptyCart");
    if (elm) {
      elm.style.height = (window.innerHeight-235)+'px';
    }
    if (elm2) {
      elm2.style.height = (window.innerHeight-112)+'px';
    }
  }
});

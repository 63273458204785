
import {computed, defineComponent, reactive, watch,ref} from "vue";
import Search from "@/components/backoffice/search/Search.vue";
import Date from "@/components/backoffice/date/Date.vue";
import {toolbarWidthFluid} from "@/core/helpers/config";
import store from "@/store";
import {useRoute} from "vue-router";
import {l} from "@/core/helpers/lang"

export default defineComponent({
  name: "KToolbar",
  props: {
    breadcrumbs: Array,
    title: String,
    key: Object
  },
  components: {
    Search,
    Date
  },
  data() {
    return {
      showSearch: false,
    }
  },
  setup() {
    const route = useRoute();
    const carts = computed(() => store.getters.currentUser.carts);
    const items = ref([]);
    const totalQty = ref(0);

    watch(carts, (newValue, oldValue) => {
      items.value=newValue.items;
      totalQty.value=newValue.totalQty;
    })
    return {
      toolbarWidthFluid,
      paramID: computed(() => route.params.key),
      dataAmount: computed(() => store.getters.getEarningList.dataAmount),
      lang: computed(() => store.getters.getLangList),
      carts,
      items,
      totalQty,
      l,
    };
  },
  computed: {
    isEarnings() {
      return useRoute().name == 'Earnings'
    }
  }
});

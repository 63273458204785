<template>
  <div class="btn btn-sm btn-flex btn-light btn-active-light-primary fw-bolder me-1"
       @click="showSearch = true; $nextTick(() =>  $refs.refSearchInput.focus())"
       v-if="showSearch===false">
      <span class="svg-icon svg-icon-3 svg-icon-gray-500 m-0">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
             viewBox="0 0 24 24" version="1.1">
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <rect x="0" y="0" width="24" height="24"></rect>
            <path
                d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
            <path
                d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                fill="#000000" fill-rule="nonzero"></path>
          </g>
        </svg>
      </span>
  </div>
  <div class="d-flex align-items-center me-1" v-if="showSearch">
    <!--begin::Svg Icon | path: icons/duotone/General/Search.svg-->
    <span class="svg-icon svg-icon-1 position-absolute ms-6">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
           viewBox="0 0 24 24" version="1.1">
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <rect x="0" y="0" width="24" height="24"></rect>
          <path
              d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
              fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
          <path
              d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
              fill="#000000" fill-rule="nonzero"></path>
        </g>
      </svg>
    </span>
    <!--end::Svg Icon-->
    <input type="text" data-kt-customer-table-filter="search" v-model='searchInput' ref="refSearchInput"
           @blur="searchInput ? void 0 : showSearch = false"
           class="form-control form-control-solid w-150px ps-15 btn-sm" :placeholder="l('web:languages:Search')">
  </div>
</template>

<script>
import {watch, ref, defineComponent} from "vue";
import {useRoute} from "vue-router";
import useSearchInput from "@/components/backoffice/composables/search.js";
import {l} from "@/core/helpers/lang"

export default defineComponent({
  name: "Search",
  setup() {
    const {setSearch} = useSearchInput();
    const route = useRoute();
    const searchInput = ref('');
    const showSearch = ref(false);

    watch(searchInput, (newVal, oldVal) => {
          setSearch(newVal, 700);
        }
    )
    watch(route, (to, from) => {
      searchInput.value = '';
      showSearch.value = false;
    })

    return {
      searchInput,
      showSearch,
      l
    };
  },
});
</script>

<template>
  <a href="#" @click="removeOne(this.productID,this.qty,this.productItemID)" class="btn btn-sm btn-light-success btn-icon me-2">
    <i class="fas fa-minus"></i>
  </a>
  <span class="me-2 fw-bolder">{{ this.qty }}</span>
  <a href="#" class="btn btn-sm btn-light-success btn-icon" @click="addOne(this.productID)">
    <i class="fas fa-plus"></i>
  </a>
</template>

<script>

import get from "@/core/functions/get" ;
import {api_url} from "@/ayar";

import store from "@/store";
import {Mutations} from "@/store/enums/StoreEnums";
import toast from "@/core/functions/toast";
import {l} from "@/core/helpers/lang"
import post from "@/core/functions/post";

export default {
  name: "cartAddRemoveButton",
  props: {
    productID: {
      type: [Number, String]
    },
    qty: {
      type: [Number, String]
    },
    productItemID: {
      type: [Number, String]
    }
  },
  methods: {
    addOne(productID) {
      get(api_url + `addcart/` + productID).then(res => {
        if(res.data.msg===0){
          toast(l('web:success_message:out_stock'),false);
        }else if(res.data.msg==='digital'){
          toast(l('web:success_message:already_cart'),false);
        }else {
          toast(l('web:success_message:add_cart'));
        }
        store.commit(Mutations.SET_CART,res.data.carts);
      });
    },
    removeOne(productID,qty,itemID) {
      if (qty <= 1) {
        get(api_url + `removecart/` + productID).then(res => {
          store.commit(Mutations.SET_CART, res.data.carts);
        });
      } else {
        post(api_url + `reducebyone`, {
          id: productID,
          itemid: itemID
        }).then(res => {
          if (res.data.msg === 0) {
            toast(l('web:success_message:out_stock'), false);
          } else if (res.data.msg === 'digital') {
            toast(l('web:success_message:already_cart'), false);
          } else {
            toast(l('web:success_message:add_cart'));
          }
          store.commit(Mutations.SET_CART, res.data.carts);
        });
      }
    }
  },
}
</script>


<template>
  <div>
    <div class="btn btn-sm btn-light btn-active-light-primary fw-bolder me-1"
         @click="showDateInput(); $nextTick(() =>  $refs.refDateInput.focus())"
         v-show="! showDate">
        <span class="svg-icon svg-icon-3 svg-icon-gray-500 m-0">
          <span class="svg-icon-gray-500 ">
            <i class="fa fa-calendar-alt fa-2x"></i>
          </span>
        </span>
    </div>
    <div class="d-flex align-items-center me-1">
      <div class="input-group" v-show="showDate">
        <input class="form-control form-control-solid btn-sm" ref="refDateInput"
               placeholder="Pick date rage" id="backoffice_daterangepicker"/>
        <button @click="hideDateInput" type="button" class="btn btn-light btn-active-light-dark text-dark btn-sm">X
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import 'daterangepicker';
import 'daterangepicker/daterangepicker.css'
import moment from 'moment';
import {onMounted, reactive, ref, watch} from "vue";
import {useRoute} from "vue-router";
import useDateInput from "../composables/date";

const dateInput = reactive({start : '', end : ''});

export default {
  name: "Date",
  setup() {
    const {setDate} = useDateInput();
    const route = useRoute();
    const showDate = ref(false);
    const start = moment().subtract(29, "days");
    const end = moment();

    const showDateInput = () => {
      showDate.value = true;
      dateInput.start = start.format('DD.MM.YYYY');;
      dateInput.end = end.format('DD.MM.YYYY');
      $("#backoffice_daterangepicker").data('daterangepicker').setStartDate(start);
      $("#backoffice_daterangepicker").data('daterangepicker').setEndDate(end)  ;
    }

    const hideDateInput = () => {
      showDate.value = false;
      dateInput.start = '';
      dateInput.end = ''
      $("#backoffice_daterangepicker").data('daterangepicker').setStartDate(start);
      $("#backoffice_daterangepicker").data('daterangepicker').setEndDate(end)  ;
    }

    const today = "Bugün";
    const yesterday = "Yesterday";
    const oneWeek = "Son 7 Gün";
    const oneMonth = "Son 30 Gün";
    const thisMonth = "Bu Ay";
    const lastMonth = "Geçen Ay";

    onMounted(() => {
      function cb(start, end) {
        $("#backoffice_daterangepicker").html(start.format("MMMM D, YYYY") + " - " + end.format("MMMM D, YYYY"));
        // update store date..
        dateInput.start = start.format('DD.MM.YYYY');
        dateInput.end = end.format('DD.MM.YYYY');
      }

      $("#backoffice_daterangepicker").daterangepicker({
        startDate: start,
        endDate: end,
        locale: {
          format: "DD/MM/YYYY",
          separator: " - ",
          applyLabel: "Uygula",
          cancelLabel: "İptal",
          fromLabel: "From",
          toLabel: "To",
          customRangeLabel: "Özel Aralık",
          weekLabel: "W",
          daysOfWeek: [
            "Paz",
            "Pzt",
            "Sal",
            "Çar",
            "Per",
            "Cum",
            "Cmt"
          ],
          monthNames: [
            "Ocak",
            "Şubat",
            "Mart",
            "Nisan",
            "Mayıs",
            "Haziran",
            "Temmuz",
            "Ağustos",
            "Eylül",
            "Ekim",
            "Kasım",
            "Aralık"
          ],
          firstDay: 1
        },
        opens: 'left',
        ranges: {
          [today]: [moment(), moment()],
          [yesterday]: [moment().subtract(1, "days"), moment().subtract(1, "days")],
          [oneWeek]: [moment().subtract(6, "days"), moment()],
          [oneMonth]: [moment().subtract(29, "days"), moment()],
          [thisMonth]: [moment().startOf("month"), moment().endOf("month")],
          [lastMonth]: [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")]
        }
      }, cb);

      //cb(start, end);

      $('#backoffice_daterangepicker').on('cancel.daterangepicker', function(ev, picker) {
        //do something, like clearing an input
      });

    });

    watch(dateInput, (newVal, oldVal) => {
          setDate(newVal);
        }
    )
    watch(route, (to, from) => {
      showDate.value = false;
    })

    return {
      showDateInput,
      hideDateInput,
      dateInput,
      showDate
    };
  },
}
</script>
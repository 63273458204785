const DocMenuConfig = (l) => [
    {
        heading: "Menü",
        route: "/",
        pages: [
            {
                heading: l('admin:genel:dashboard'),
                route: "/ozet",
                svgIcon: "/media/icons/duotune/Design/PenAndRuller.svg",
            },
            /*{
              heading: "layoutBuilder",
              route: "/builder",
              svgIcon: "/media/icons/duotune/general/gen019.svg",
              fontIcon: "bi-layers",
            },*/
        ],
    },
    {
        heading: "",
        route: "/",
        pages: [
            {
                sectionTitle: l('admin:genel:siparisler'),
                route: "/myOrders",
                svgIcon: "/media/icons/duotune/communication/Clipboard-list.svg",
                sub: [
                    {
                        heading: l('web:transfer:Siparişlerim'),
                        route: "/my-orders",
                    },
                    {
                        heading: l('web:transfer:Üye Siparişleri'),
                        route: "/customer-orders",
                    },
                    {
                        heading: l('user:purcased_items:Müşteri Siparişleri'),
                        route: "/dealer-orders",
                    }
                ],
            },
            {
                sectionTitle: l('admin:genel:musteri_yonetimi'),
                route: "/myCustomers",
                svgIcon: "/media/icons/duotune/general/User.svg",
                sub: [
                    {
                        heading: l('admin:transfer:Müşterilerim'),
                        route: "/my-customers",
                    },
                    {
                        heading: l('admin:transfer:Müşteri Adaylarım'),
                        route: "/potential-customers",
                    }
                ],
            },
            {
                sectionTitle: l('admin:transfer:Bayi Yönetimi'),
                route: "/dealers",
                svgIcon: "/media/icons/duotune/communication/Group.svg",
                sub: [
                    {
                        heading: l('admin:genel:yeni_bayi'),
                        route: "/add-new-dealer",
                    },
                    {
                        heading: l('admin:genel:bayilerim'),
                        route: "/my-dealer",
                    },
                    {
                        heading: l('admin:transfer:Bayi Olmayanlar'),
                        route: "/potential-dealer",
                    }
                ],
            },
            {
                heading: l('web:languages:languages473'),
                route: "/bayi-haritasi",
                fontIcon: "fas fa-search-location",
            },
            {
                sectionTitle: l('admin:transfer:Ağaç Yönetimi'),
                route: "/networkTree",
                svgIcon: "/media/icons/duotune/communication/Tree.svg",
                sub: [
                    {
                        heading: l('admin:genel:network_agacim'),
                        route: "/network_tree",
                    },
                    {
                        heading: l('admin:genel:unilevel_agacim'),
                        route: "/unilevel_tree",
                    }, {
                        heading: l('admin:transfer:Yerleşim Bekleyenler'),
                        route: "/waiting_room",
                    }
                ],
            },
        ],
    },
    {
        heading: l('admin:transfer:Gelir Yönetimi'),
        route: "/",
        pages: [
            {
                sectionTitle: l('admin:transfer:gelirler'),
                route: "/earnings",
                svgIcon: "/media/icons/duotune/communication/Money.svg",
                sub: 'earningList.gelir',
            },
            {
                sectionTitle: l('admin:transfer:Puanlar'),
                route: "/points",
                svgIcon: "/media/icons/duotune/Shopping/Wallet.svg",
                sub: 'earningList.puan',
            },
            {
                heading: l('admin:transfer:Bonuslar'),
                route: "/bonus",
                svgIcon: "/media/icons/duotune/Shopping/Wallet.svg",
            },
            {
                heading: l('admin:transfer:Belgeler'),
                route: "/files",
                svgIcon: "/media/icons/duotune/communication/Documents.svg",
            },
            {
                heading: l('admin:transfer:earnings_description'),
                route: "/earning/description",
                svgIcon: "/media/icons/duotune/communication/Kazanc.svg",
            },
        ],
    },
    {
        heading: " ",
        route: "/",
        pages: [
            {
                sectionTitle: l('web:languages:lanugages474'),
                route: "/careers",
                svgIcon: "/media/icons/duotune/communication/Kariyer.svg",
                sub: [
                    {
                        heading: l('admin:transfer:Kariyer Hacmi'),
                        route: "/career_volume",
                    },
                    {
                        heading: l('web:languages:lanugages474'),
                        route: "/career",
                    },
                    {
                        heading: l('admin:transfer:Kariyer Göstergesi'),
                        route: "/career_indicator",
                    }
                ],
            },
            {
                sectionTitle: l('web:transfer:Diğer'),
                route: "/diger",
                svgIcon: "/media/icons/duotune/Design/Edit.svg",
                sub: [
                    {
                        heading: l('admin:transfer:Sözleşmeler'),
                        route: "/sozlesmeler",
                    },
                    {
                        sectionTitle: l('web:transfer:Yardım & Destek'),
                        route: "/ticket",
                        svgIcon: "/media/icons/duotune/communication/Group-chat.svg",
                        sub: [
                            {
                                heading: l('admin:languages:languages442'),
                                route: "/messages",
                            },
                            {
                                heading: l('admin:languages:languages441S'),
                                route: "/ticket",
                            },
                            {
                                heading: l('admin:genel:sikayet'),
                                route: "/complaint",
                            }
                        ],
                    },
                ]
            },
            {
                sectionTitle: l('web:transfer:Alışveriş'),
                route: "/alisveris",
                svgIcon: "/media/icons/duotune/communication/Shop.svg",
                sub: [
                    {
                        heading: l('admin:transfer:urunler'),
                        route: "/products",
                        svgIcon: "/media/icons/duotune/general/Duplicate.svg",
                    },
                    {
                        heading: l('admin:transfer:packet_groups'),
                        route: "/packet_groups",
                        svgIcon: "/media/icons/duotune/general/Duplicate.svg",
                    },
                ]
            },


        ]
    },
    /*{
      heading: "craft",
      route: "/crafted",
      pages: [
        {
          sectionTitle: "pages",
          route: "/pages",
          svgIcon: "/media/icons/duotune/general/gen022.svg",
          fontIcon: "bi-archive",
          sub: [
            {
              sectionTitle: "profile",
              route: "/profile",
              sub: [
                {
                  heading: "profileOverview",
                  route: "/crafted/pages/profile/overview",
                },
                {
                  heading: "projects",
                  route: "/crafted/pages/profile/projects",
                },
                {
                  heading: "campaigns",
                  route: "/crafted/pages/profile/campaigns",
                },
                {
                  heading: "documents",
                  route: "/crafted/pages/profile/documents",
                },
                {
                  heading: "connections",
                  route: "/crafted/pages/profile/connections",
                },
                {
                  heading: "activity",
                  route: "/crafted/pages/profile/activity",
                },
              ],
            },
            {
              sectionTitle: "wizards",
              route: "/wizard",
              sub: [
                {
                  heading: "horizontal",
                  route: "/crafted/pages/wizards/horizontal",
                },
                {
                  heading: "vertical",
                  route: "/crafted/pages/wizards/vertical",
                },
              ],
            },
          ],
        },
        {
          sectionTitle: "account",
          route: "/account",
          svgIcon: "/media/icons/duotune/communication/com006.svg",
          fontIcon: "bi-person",
          sub: [
            {
              heading: "accountOverview",
              route: "/crafted/account/overview",
            },
            {
              heading: "settings",
              route: "/crafted/account/settings",
            },
          ],
        },
        {
          sectionTitle: "authentication",
          svgIcon: "/media/icons/duotune/technology/teh004.svg",
          fontIcon: "bi-sticky",
          sub: [
            {
              sectionTitle: "basicFlow",
              sub: [
                {
                  heading: "signIn",
                  route: "/sign-in",
                },
                {
                  heading: "signUp",
                  route: "/sign-up",
                },
                {
                  heading: "passwordReset",
                  route: "/password-reset",
                },
              ],
            },
            {
              heading: "error404",
              route: "/404",
            },
            {
              heading: "error500",
              route: "/500",
            },
          ],
        },
        {
          sectionTitle: "modals",
          route: "/modals",
          svgIcon: "/media/icons/duotune/art/art009.svg",
          fontIcon: "bi-shield-check",
          sub: [
            {
              sectionTitle: "general",
              route: "/general",
              sub: [
                {
                  heading: "inviteFriends",
                  route: "/crafted/modals/general/invite-friends",
                },
                {
                  heading: "viewUsers",
                  route: "/crafted/modals/general/view-user",
                },
                {
                  heading: "upgradePlan",
                  route: "/crafted/modals/general/upgrade-plan",
                },
                {
                  heading: "shareAndEarn",
                  route: "/crafted/modals/general/share-and-earn",
                },
              ],
            },
            {
              sectionTitle: "forms",
              route: "/forms",
              sub: [
                {
                  heading: "newTarget",
                  route: "/crafted/modals/forms/new-target",
                },
                {
                  heading: "newCard",
                  route: "/crafted/modals/forms/new-card",
                },
                {
                  heading: "newAddress",
                  route: "/crafted/modals/forms/new-address",
                },
                {
                  heading: "createAPIKey",
                  route: "/crafted/modals/forms/create-api-key",
                },
              ],
            },
            {
              sectionTitle: "wizards",
              route: "/wizards",
              sub: [
                {
                  heading: "twoFactorAuth",
                  route: "/crafted/modals/wizards/two-factor-auth",
                },
                {
                  heading: "createApp",
                  route: "/crafted/modals/wizards/create-app",
                },
                {
                  heading: "createAccount",
                  route: "/crafted/modals/wizards/create-account",
                },
              ],
            },
          ],
        },
        {
          sectionTitle: "widgets",
          route: "/widgets",
          svgIcon: "/media/icons/duotune/general/gen025.svg",
          fontIcon: "bi-layers",
          sub: [
            {
              heading: "widgetsLists",
              route: "/crafted/widgets/lists",
            },
            {
              heading: "widgetsStatistics",
              route: "/crafted/widgets/statistics",
            },
            {
              heading: "widgetsCharts",
              route: "/crafted/widgets/charts",
            },
            {
              heading: "widgetsMixed",
              route: "/crafted/widgets/mixed",
            },
            {
              heading: "widgetsTables",
              route: "/crafted/widgets/tables",
            },
            {
              heading: "widgetsFeeds",
              route: "/crafted/widgets/feeds",
            },
          ],
        },
      ],
    },
    {
      heading: "apps",
      route: "/apps",
      pages: [
        {
          sectionTitle: "customers",
          route: "/customers",
          svgIcon: "/media/icons/duotune/finance/fin006.svg",
          fontIcon: "bi-printer",
          sub: [
            {
              heading: "gettingStarted",
              route: "/apps/customers/getting-started",
            },
            {
              heading: "customersListing",
              route: "/apps/customers/customers-listing",
            },
            {
              heading: "customerDetails",
              route: "/apps/customers/customer-details",
            },
          ],
        },
        {
          sectionTitle: "chat",
          route: "/chat",
          svgIcon: "/media/icons/duotune/communication/com012.svg",
          fontIcon: "bi-chat-left",
          sub: [
            {
              heading: "privateChat",
              route: "/apps/chat/private-chat",
            },
            {
              heading: "groupChat",
              route: "/apps/chat/group-chat",
            },
            {
              heading: "drawerChat",
              route: "/apps/chat/drawer-chat",
            },
          ],
        },
      ],
    },*/
];

export default DocMenuConfig;


import {defineComponent, computed} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import {Actions} from "@/store/enums/StoreEnums";
import {getUiAvatarSrc} from "@/ayar";
import {l} from '@/core/helpers/lang'

export default defineComponent({
  name: "kt-user-menu",
  components: {},
  setup() {
    const router = useRouter();
    const store = useStore();

    const countries = {
      tr: {
        flag: "media/flags/turkey.svg",
        name: "Türkçe",
      },
      en: {
        flag: "media/flags/united-states.svg",
        name: "English",
      },
      es: {
        flag: "media/flags/spain.svg",
        name: "Spanish",
      },
      de: {
        flag: "media/flags/germany.svg",
        name: "German",
      },
      ja: {
        flag: "media/flags/japan.svg",
        name: "Japanese",
      },
      fr: {
        flag: "media/flags/france.svg",
        name: "French",
      },
    };

    const signOut = () => {
      router.push({name: "logout"});
    };

    const setLang = (lang) => {
      localStorage.setItem("lang", lang);
      // todo: post change language..
    };

    const currentLanguage = (lang) => {
      // todo: check if current language
      // return i18n.locale.value === lang;
      return !!lang;
    };

    const currentLangugeLocale = computed(() => {
      return countries['tr'];
    });

    return {
      signOut,
      setLang,
      currentLanguage,
      currentLangugeLocale,
      countries,
      getUiAvatarSrc,
      user: computed(() => store.getters.currentUser),
      l
    };
  }
});


import {computed, defineComponent} from "vue";
import KTTopbar from "@/layout/header/Topbar.vue";
import KTMenu from "@/layout/header/Menu.vue";
import {l} from "@/core/helpers/lang";
import {base_url} from "@/ayar";
import ShareModal from "@/components/backoffice/modal/ShareModal.vue";
import Popper from "vue3-popper";


import {
  headerWidthFluid,
  headerLeft,
  asideDisplay,
} from "@/core/helpers/config";
import store from "@/store";

export default defineComponent({
  name: "KTHeader",
  props: {
    title: String,
  },
  components: {
    KTTopbar,
    ShareModal,
    Popper
    // KTMenu,
  },

  setup() {
    return {
      headerWidthFluid,
      headerLeft,
      asideDisplay,
      generalSettings: computed(() => store.getters.getGeneralSettingsList),
      user: computed(() => store.getters.currentUser),
      l,
      base_url,
    };
  },
});

<template>
  <div class="modal fade" id="kt_modal_share_earn" tabindex="-1" aria-modal="true" role="dialog">
    <div class="modal-dialog modal-dialog-centered mw-800px">
      <div class="modal-content">
        <div class="modal-header pb-0 border-0 justify-content-end">
          <div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
            <span class="svg-icon svg-icon-1">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black"></rect>
                <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black"></rect>
              </svg>
            </span>
          </div>
        </div>
        <div class="modal-body scroll-y pt-0 pb-15">
          <div class="mw-lg-600px mx-auto">
            <div class="mb-13 text-center">
              <h1 class="mb-3">{{ l ( 'web:transfer:Paylaş & Kazan' ) }}</h1>
              <div class="text-muted fw-bold fs-5">
              </div>
              <div class="mb-10">
                <div class="d-flex">
                  <input readonly type="text" class="form-control form-control-solid me-3 flex-grow-1" :value="copyLink+user.sponsor_id">
                  <button class="btn btn-light fw-bolder flex-shrink-0" @click="copyClipboard(copyLink+user.sponsor_id)">{{ l ( 'web:genel:Kopyala' ) }}</button>
                </div>
              </div>
              <div class="d-flex">
                <a :href="url.whatsapp+copyLink+user.sponsor_id" class="btn btn-light w-100" target="_blank">
                  <i class="fab fa-whatsapp fs-2 text-success"></i>
                  Whatsapp
                </a>
                <a :href="url.facebook+copyLink+user.sponsor_id" class="btn btn-light w-100 mx-6" target="_blank">
                  <i class="fab fa-facebook fs-2" style="color:#0d6efd"></i>
                  Facebook
                </a>
                <a :href="url.twitter+copyLink+user.sponsor_id" class="btn btn-light w-100" target="_blank">
                  <i class="fab fa-twitter fs-2 text-primary"></i>
                  Twitter
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {computed} from 'vue'
import {l} from "@/core/helpers/lang"
import {base_url} from '@/ayar';
import store from '@/store';
import copyClipboard from '@/core/helpers/copyClipboard';

export default {
  name: 'ShareModal',
  data() {
    return {
      url: {
        whatsapp:'https://api.whatsapp.com/send?text=',
        facebook:'https://www.facebook.com/share.php?u=',
        twitter:'https://twitter.com/intent/tweet?url=',
      },
    }
  },
  setup() {
    return {
      l,
      user :computed(() => store.getters.currentUser),
      copyLink:base_url+'?sid=',
      copyClipboard,
    }
  }
};
</script>


<template>
  <!--begin::Footer-->
  <div class="footer d-print-none py-4 d-flex flex-lg-column d-none d-sm-block" id="kt_footer">
    <!--begin::Container-->
    <div
        class="
        d-flex
        flex-column flex-md-row
        align-items-center
        justify-content-between
      "
        :class="{
        'container-fluid': footerWidthFluid,
        'container-xxl': !footerWidthFluid,
      }"
    >
      <!--begin::Copyright-->
      <div class="text-dark order-2 order-md-1">
        <span class="text-muted fw-bold me-1">2021&copy;</span>
        <a
            href="https://emcbilisim.com/"
            target="_blank"
            class="text-gray-800 text-hover-primary"
        >EMC</a
        >
      </div>
      <!--end::Copyright-->

      <!--begin::Menu-->
      <ul class="menu menu-gray-600 menu-hover-primary fw-bold order-1">
        <li class="menu-item">
          <a href="#" target="_blank" class="menu-link px-2">Hakkında</a>
        </li>

        <li class="menu-item">
          <a href="#" target="_blank" class="menu-link px-2">Destek</a>
        </li>
      </ul>
      <!--end::Menu-->
    </div>
    <!--end::Container-->
  </div>
  <div class="footer fixed-bottom align-items-stretch  d-sm-none border-top p-3 d-print-none">
    <div class="row">
      <!--begin::Home-->
      <div class="col align-items-center text-center">
        <!--begin::Menu-->
        <div
            class="
          btn btn-icon btn-active-light-primary
          w-30px
          h-30px
          w-md-40px
          h-md-40px
        "
            data-kt-menu-trigger="click"
            data-kt-menu-attach="parent"
            data-kt-menu-placement="bottom-end"
            data-kt-menu-flip="bottom"
        >
          <router-link to="/ozet">
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/Home/Home.svg"/>
            </span>
          </router-link>
        </div>
        <!--end::Menu-->
      </div>
      <!--end::Home-->
      <!--begin::Quick links-->
      <div class="col align-items-center text-center">
        <!--begin::Menu-->
        <div
            class="
          btn btn-icon btn-active-light-primary
          w-30px
          h-30px
          w-md-40px
          h-md-40px
        "
            data-kt-menu-trigger="click"
            data-kt-menu-attach="parent"
            data-kt-menu-placement="bottom-end"
            data-kt-menu-flip="bottom"
        >
        <span class="svg-icon svg-icon-1">
          <inline-svg src="/media/icons/duotune/general/gen025.svg"/>
        </span>
        </div>
        <KTQuickLinksMenu></KTQuickLinksMenu>
        <!--end::Menu-->
      </div>
      <!--end::Quick links-->

          <!--begin::Messages-->
    <div class="col align-items-center text-center">
      <!--begin::Menu-->
      <div
        class="
          btn btn-icon btn-active-light-primary
          position-relative
          w-30px
          h-30px
          w-md-40px
          h-md-40px
        "
        data-kt-menu-trigger="click"
        data-kt-menu-attach="parent"
        data-kt-menu-placement="bottom-end"
        data-kt-menu-flip="bottom"
      >
        <span class="svg-icon svg-icon-1">
          <inline-svg src="/media/icons/duotune/communication/com012.svg" />
        </span>
        <span
          class="
            bullet bullet-dot
            bg-success
            h-6px
            w-6px
            position-absolute
            translate-middle
            top-0
            start-50
            animation-blink
          "
        >
        </span>
      </div>
      <Messages></Messages>
      <!--end::Menu-->
    </div>
    <!--end::Messages-->

<!--      &lt;!&ndash;begin::Chat&ndash;&gt;-->
<!--      <div class="col align-items-center text-center">-->
<!--        &lt;!&ndash;begin::Menu wrapper&ndash;&gt;-->
<!--        <div-->
<!--            class="-->
<!--          btn btn-icon btn-active-light-primary-->
<!--          position-relative-->
<!--          w-30px-->
<!--          h-30px-->
<!--          w-md-40px-->
<!--          h-md-40px-->
<!--        "-->
<!--            id="kt_drawer_chat_toggle"-->
<!--        >-->
<!--        <span class="svg-icon svg-icon-1">-->
<!--          <inline-svg src="media/icons/duotune/communication/com012.svg"/>-->
<!--        </span>-->

<!--          <span-->
<!--              class="-->
<!--            bullet bullet-dot-->
<!--            bg-success-->
<!--            h-6px-->
<!--            w-6px-->
<!--            position-absolute-->
<!--            translate-middle-->
<!--            top-0-->
<!--            start-50-->
<!--            animation-blink-->
<!--          "-->
<!--          >-->
<!--        </span>-->
<!--        </div>-->
<!--        &lt;!&ndash;end::Menu wrapper&ndash;&gt;-->
<!--      </div>-->
<!--      &lt;!&ndash;end::Chat&ndash;&gt;-->

      <!--begin::Notifications-->
      <div class="col align-items-center text-center">
        <!--begin::Menu-->
        <div
            class="
          btn btn-icon btn-active-light-primary
          position-relative
          w-30px
          h-30px
          w-md-40px
          h-md-40px
        "
            data-kt-menu-trigger="click"
            data-kt-menu-attach="parent"
            data-kt-menu-placement="bottom-end"
            data-kt-menu-flip="bottom"
        >
        <span class="svg-icon svg-icon-1">
          <inline-svg src="/media/icons/duotune/general/gen022.svg"/>
        </span>
        </div>
        <KTNotificationsMenu></KTNotificationsMenu>
        <!--end::Menu-->
      </div>
      <div class="col align-items-center text-center"></div>
      <!--end::Notifications-->
    </div>
  </div>
  <!--end::Footer-->
  <!--  Product Button-->
  <div class="sticky d-sm-none">
    <div class="roundedButton btn-primary" @click="toggleDropdown()" @blur="close" ref="btnDropdownRef" tabindex="0">
      <i class="fas fa-plus text-light fs-4"></i>
    </div>
    <div v-bind:class="{'d-none': !dropdownPopoverShow, 'd-block': dropdownPopoverShow}" class="dropdown-menu"  ref="popoverDropdownRef" >
      <router-link to="/products" class="dropdown-item">
        {{ l('web:transfer:Ürün Satın Al') }}
      </router-link>
      <router-link to="/packet_groups" class="dropdown-item" v-if="generalSettings.sistemde_paket_olacak_mi">
        {{ l('web:transfer:Paket Satın Al') }}
      </router-link>
      <router-link to="/add-new-dealer" class="dropdown-item" >
        {{ l('web:transfer:Yeni Bayi Ekle') }}
      </router-link>
    </div>
  </div>
  <!--  Product Button-->
</template>

<script>
import {
  computed,
  defineComponent
} from 'vue';
import {useStore} from "vuex";
import {footerWidthFluid} from "@/core/helpers/config";
import KTNotificationsMenu from "@/layout/header/partials/NotificationsMenu.vue";
import KTQuickLinksMenu from "@/layout/header/partials/QuickLinksMenu.vue";
import { createPopper } from "@popperjs/core";
import {l} from "@/core/helpers/lang"
import Messages from "@/layout/header/partials/Messages";


export default defineComponent({
  name: "KTFooter",
  data() {
    return {
      dropdownPopoverShow: false
    }
  },
  components: {
    KTNotificationsMenu,
    KTQuickLinksMenu,
    Messages
  },
  methods:{
    close(){
      setTimeout(() => {
        this.dropdownPopoverShow = false
      }, 200);

    }
  },
  setup() {
    const store = useStore();
    function toggleDropdown (){
      if(this.dropdownPopoverShow){
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "top-end"
        });
      }
    }
    return {
      footerWidthFluid,
      toggleDropdown,
      generalSettings:computed(()=>store.getters.getGeneralSettingsList),
      l
    };
  },
});
</script>


import {computed, defineComponent, onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import {ScrollComponent} from "@/assets/ts/components/_ScrollComponent";
import {MenuComponent} from "@/assets/ts/components/MenuComponent";
import {version} from "@/core/helpers/documentation";
import {asideMenuIcons} from "@/core/helpers/config";
import MainMenuConfig from "@/core/config/MainMenuConfig";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import _ from "lodash"
import {l}           from "@/core/helpers/lang";

export default defineComponent({
  name: "kt-menu",
  components: {},
  setup() {
    const route = useRoute();
    let newMenuItem = computed(function () {
      return store.getters.getEarningList.name;
    });
    const checkPoint = () =>{
      return _.filter(newMenuItem.value, ['type', "point"]).length;
    }
    const scrollElRef = ref<null | HTMLElement>(null);
    onMounted(() => {
      ScrollComponent.reinitialization();
      MenuComponent.reinitialization();
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
      store.dispatch(Actions.SET_EARNING_LIST_ACTION);
    });


    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };
    return {
      hasActiveChildren,
      MainMenuConfig,
      asideMenuIcons,
      version,
      newMenuItem,
      checkPoint,
      l
    };
  },
});

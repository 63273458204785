import toast from "@/core/functions/toast";
import {l} from "@/core/helpers/lang";

export default function copyClipboard ( text )
{
    navigator.clipboard.writeText(text);

    toast(l('admin:genel:success'));


}